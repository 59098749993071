import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

function IosPage() {
    const classes = useStyles();

    return (
        <>
            <div className={classes.content}>
                <h1 className={classes.heading}>Installationsanleitung iOS</h1>
                <h2 className={classes.heading}>Installiere Test Flight und installiere die App</h2>
                <Button className={classes.downloadBtn} color='secondary' variant='contained' startIcon={<GetApp />}
                    href="https://testflight.apple.com/join/H7yNkylB" target="_blank">Installieren</Button>
                <p><strong>Beachte:</strong> Falls du Testflight noch nicht installiert hast, musst du eventuell nochmals auf den Button klicken.</p>
            </div>
        </>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        heading: {
            textAlign: 'center'
        },
        downloadBtn: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 30,
            paddingRight: 30
        }
    }),
);

export default IosPage;
