import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { Button } from '@material-ui/core';
import PlatformCard from './components/platformCard';
import IosPage from './Views/IosPage';
import { ArrowBack } from '@material-ui/icons';
import AndroidPage from './Views/AndroidPage';

function App() {
  const classes = useStyles();

  const [selectedMenu, setSelectedMenu] = useState<string>('None')

  return (
    <div className={classes.app}>
      <header className="App-header">
        <AppBar position="fixed" color='primary'>
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <img className={classes.logoImg} src={'icon/favicon-256x256.png'} alt="logo" />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Nutrition Rating
            </Typography>
          </Toolbar>
        </AppBar>
      </header>
      <div className={classes.content}>
        {/* Select Platform */}
        {selectedMenu === 'None' &&
          <div className={classes.cards}>
            <PlatformCard
              imageUrl={'assets/android_logo.svg'}
              heading={'Ich habe ein Android Handy'}
              onPress={() => { setSelectedMenu('android') }}
            />
            <PlatformCard
              imageUrl={'assets/apple_logo.svg'}
              heading={'Ich habe ein Apple iPhone'}
              onPress={() => { setSelectedMenu('ios') }}
            />

          </div>
        }
        {(selectedMenu === 'ios' || selectedMenu === 'android') &&
          <Button color='secondary' variant='outlined' startIcon={<ArrowBack />}
            onClick={() => setSelectedMenu('None')}>Back</Button>
        }
        {selectedMenu === 'ios' &&
          <IosPage />
        }
        {selectedMenu === 'android' &&
          <AndroidPage />
        }
      </div>
    </div >
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    app: {
      height: '99vh',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
        height: '80vh',
        overflow: 'initial',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    logoImg: {
      width: '40px'
    },
    content: {
      marginTop: 70,
      padding: 20,
    },
    cards: {
      marginTop: 30,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      },
    }
  }),
);

export default App;
