import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { GetApp, OpenInNew } from '@material-ui/icons';

function AndroidPage() {
    const classes = useStyles();

    const onDownload = () => {
        const link = document.createElement('a');
        link.download = `receipt-rating.apk`;
        link.href = './assets/receipt-rating.apk';
        link.click();
    };

    const onTutorial = () => {
        const link = document.createElement('a');
        link.href = 'https://www.heise.de/tipps-tricks/Externe-Apps-APK-Dateien-bei-Android-installieren-so-klappt-s-3714330.html';
        link.target = '_blank'
        link.click();
    }

    return (
        <>
            <div className={classes.content}>
                <h1 className={classes.heading}>Installationsanleitung Android</h1>
                <h2 className={classes.heading}>1. Downloade die Installationsdatei (Apk)</h2>
                <form method="get" action="file.doc">
                    <Button className={classes.downloadBtn} color='secondary' variant='contained' startIcon={<GetApp />}
                        onClick={onDownload}>Download App</Button>
                </form>
                <h2 className={classes.heading}>2. Installiere die Datei</h2>
                <p><strong>Beachte:</strong> Da standartmäßig Installationen außerhalb des Play Stores blockiert werden,
                    musst du unter Umständen die Installation aus <strong>unbekannten Quellen aktivieren</strong>. Nach der Installation der
                    App wird aber empfohlen, diese Einstellung wieder rückgängig zu machen. </p>
                <form method="get">
                    <Button className={classes.downloadBtn} color='secondary' variant='outlined' startIcon={<OpenInNew />}
                        onClick={onTutorial}>Installationsanleitung für Apps außerhalb des Appstores</Button>
                </form>
            </div>
        </>
    );
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
        heading: {
            textAlign: 'center'
        },
        downloadBtn: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 30,
            paddingRight: 30
        }
    }),
);

export default AndroidPage;