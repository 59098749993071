import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

interface IPlatformCard {
    imageUrl: string,
    heading: string,
    onPress: () => void
}

function PlatformCard(props: IPlatformCard) {

    const [size, setSize] = useState<number>(window.innerHeight)

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            logo: {
                width: '50%',
                [theme.breakpoints.down('sm')]: {
                    width: '30%'
                },
            },
            cardContainer: {
                overflow: 'hidden',
                width: '50%',
                height: size - 200,
                flex: 1,
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    height: size / 2 - 150,
                },
                position: 'relative'
            },
            card: {
                padding: 20,
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 50,
                marginRight: 50,
                marginBottom: 50,
                height: '90%',
                justifyContent: 'center',
                alignItems: 'center',
                '&:hover': {
                    borderColor: '#C42A2D',
                    borderWidth: 3,
                    borderStyle: 'solid',
                    padding: 17,
                },
                [theme.breakpoints.down('sm')]: {
                    '&:hover': {
                        borderWidth: 0,
                    },
                    '&:active': {
                        borderColor: '#C42A2D',
                        borderWidth: 3,
                        borderStyle: 'solid',
                        padding: 17,
                    }
                },
            },
            cardHeading: {
                marginTop: 40,
                [theme.breakpoints.down('sm')]: {
                    marginTop: 20
                },
                textAlign: 'center'
            }
        }),
    );

    const classes = useStyles();

    const resizeHanlder = () => {
        const height = window.innerHeight;
        setSize(height);
    };

    useEffect(() => {
        window.addEventListener('resize', resizeHanlder);
    }, []);

    return (
        <div className={classes.cardContainer} onClick={props.onPress}>
            <Paper elevation={3} className={classes.card}>
                <img className={classes.logo} src={props.imageUrl} alt=''></img>
                <h1 className={classes.cardHeading}>{props.heading}</h1>
            </Paper>
        </div>
    );
}

export default PlatformCard;